<template>

{{classlist}}

  <br><br>



  <Accordion @tab-open="openAccordion($event)">



    <AccordionTab :header="clasess.klasi" v-for="(clasess,index) in classlist" :key="index"  >



      <div class="row">

        <div class="col-12">
          <table class="table table-striped">
            <thead>
            <tr>
              <th scope="col">სახელი</th>
              <th scope="col">გვარი</th>
              <th scope="col">კოდი</th>

            </tr>
            </thead>
            <tbody>
            <tr v-for="(pupil,i) in PupilsByClass " :key="i">
              <td>{{pupil.saxeli}}</td>
              <td>{{pupil.gvari}}</td>
              <td >{{pupil.kodi}}</td>

            </tr>

            </tbody>
          </table>


            </div>


            </div>


            </AccordionTab>

          </Accordion>


</template>
<script>

  import Accordion from 'primevue/accordion';
  import AccordionTab from 'primevue/accordiontab';

export default {
  data(){
    return {
      classlist:null,
      PupilsByClass:null,
    }
  },

  components:{

  Accordion ,AccordionTab
  },
      mounted() {
        this.$store.dispatch('getclasess',{branchID:this.$store.state.user.branchID,classlist:this.classlist},).then(response=>{
          this.classlist=response.data

        })
      },
  methods:{

    openAccordion(event){

      const classItem = this.classlist[event.index]

        this.$store.dispatch('getPupils',{classID:classItem}).then(response=>{

          this.PupilsByClass=response.data


        })




    }


  }
}

</script>