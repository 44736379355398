<template>

  <div class="container">


      <div class="row">
        <div class="col-5">
          <div class="col-12"></div>
          <div class="col-6">
            <input type="text" placeholder="სახელი"  class="form-control" id="fname" v-model="form.fname">
          </div>
          <div class="col-6">
            <div class="form-group mt-2">
              <input type="text" class="form-control" placeholder="გვარი" v-model="form.lname" id="lname">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-2">
              <input type="number" class="form-control" placeholder="მობილური" v-model="form.phone" id="phone">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-2">
              <input type="text" class="form-control" placeholder="მისამართი" v-model="form.adress" id="adress">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-2">
              <input type="email" class="form-control" placeholder="ელ-ფოსტა" v-model="form.email" id="email">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-2">
              <input type="text" class="form-control" placeholder="პირადობა" v-model="form.piradoba" id="piradoba" >
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-2">
              <input type="text" class="form-control" placeholder="პირადობის სერია" v-model="form.piradobisS" id="piradobisS">
            </div>
          </div>
          <div class="col-6">
            <div class="form-group mt-2">
              <label >დაბადების თარიღი</label>
              <input type="date" class="form-control" placeholder="დაბადების თარიღი" v-model="form.dabadebistarigi" id="dabadebistarigi">
            </div>
            <div class="col-6">
              <div class="form-check-inline mt-3 text-center">
                <input class="form-check-input " type="radio" name="gender" value="0" checked v-model="form.gender" >
                <label class="form-check-label mr-3 "> მამრობითი</label>

                <input class="form-check-input " type="radio" name="gender" value="1" v-model="form.gender">
                <label class="form-check-label  "> მდედრობითი</label>

              </div>
            </div>
            <div class="col-6">
              <div class="form-group">
                <input type="file"  @change="getimg">

              </div>
            </div>
            <div class="col-6">


              <div class="form-group">
                <label>აირჩიეთ ფაილი</label>
                <input type="file"  @change="getfile">

              </div>
            </div>
          </div>
        </div>
        <div class="col-7">
          <form>
            <div class="row">
              <div class="col">
                <label >ხელშეკრულების ნომერი</label>
                <input type="text" class="form-control" placeholder="" v-model="form.contract_number" id="contract_number">
              </div>
              <div class="col">
                <div class="form-group ">
                  <label >თანხა</label>
                  <input type="text" class="form-control" v-model="form.salary" id="salary">

                </div>
              </div>
            </div>
          </form>
          <form>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="form-group ">
                    <label>ხელშეკრულების ტიპი</label>
                    <Multiselect id="contract_typeID"
                                 v-model="form.contract_typeID"
                                 :options="contract_type"
                                 :searchable="true"
                    />
                  </div>
                </div>
              </div>
              <div class="col">
                <label >ხელშეკრულების გაფორმების თარიღი</label>
                <input type="date" class="form-control"  v-model="form.contract_date" id="contract_date">
              </div>
            </div>
          </form>


            <form>
              <div class="row">
                <div class="col">
                  <div class="form-group">
                      <div class="form-group">
                        <label >ხელშეკრულების დაწყების თარიღი</label>
                        <input type="date" class="form-control"  v-model="form.contract_start_date" id="contract_start_date">
                      </div>
                    </div>

                </div>
                <div class="col">
                  <div class="form-group ">
                    <label >ხელშეკრულების დასრულების თარიღი</label>
                    <input type="date" class="form-control"  v-model="form.contract_end_date" id="contract_end_date">
                  </div>
                </div>
              </div>
            </form>
          <form>
            <div class="row">
              <div class="col">
                <div class="form-group">
                  <div class="form-group mt-2">
                    <label>ხელშეკრულების  შეწყვეტის თარიღი</label>
                    <input type="date"  class="form-control" v-model="form.shewyvetis_tar" id="shewyvetis_tar">
                  </div>
                </div>

              </div>

            </div>
          </form>
          <form >
          <div class="form-group mt-2">
            <label>სტატუსი</label>
            <Multiselect id="statusID"
                         v-model="form.statusID"
                         :options="status"
                         :searchable="true"
            />
          </div>
          </form>
        </div>

      </div>
<div class="row">
  <div class="col-12">
    <div class="form-group">
      <select class="form-control" v-model="form.userlebelID" id="userlebelID">
        <option value="0" selected>აირჩიეთ როლი</option>
        <option v-for="(userlevel,i) in userlevels" :key="i" :value="userlevel.userlevelID">{{userlevel.userlevel}}</option>

      </select>


    </div>
  </div>
  <div class="col-12">
    <select class="form-control" v-model="form.profesiaID" id="profesiaID">
      <option value="0" selected>აირჩიეთ პროფესია</option>
      <option v-for="(proff,i) in profesia" :key="i" :value="proff.profesiaID">{{proff.profesia}}</option>

    </select>
  </div>
  <div class="col-12 mt-3">
    <button type="button" class="btn btn-secondary btn-block" @click="saveinfo">შენახვა</button>
  </div>
</div>

<!--         <form action="">-->
<!--           <div class="row">-->

<!--            <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--             <div class="form-group mt-2">-->
<!--               <input type="text" class="form-control" placeholder="სახელი" v-model="form.fname" id="fname">-->
<!--             </div>-->
<!--            </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <input type="text" class="form-control" placeholder="გვარი" v-model="form.lname" id="lname">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <input type="number" class="form-control" placeholder="მობილური" v-model="form.phone" id="phone">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <input type="text" class="form-control" placeholder="მისამართი" v-model="form.adress" id="adress">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <input type="email" class="form-control" placeholder="ელ-ფოსტა" v-model="form.email" id="email">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <input type="text" class="form-control" placeholder="პირადობა" v-model="form.piradoba" id="piradoba" >-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="  col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <input type="text" class="form-control" placeholder="პირადობის სერია" v-model="form.piradobisS" id="piradobisS">-->
<!--               </div>-->
<!--             </div>-->

<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label >დაბადების თარიღი</label>-->
<!--                 <input type="date" class="form-control" placeholder="დაბადების თარიღი" v-model="form.dabadebistarigi" id="dabadebistarigi">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12 col-md-4">-->
<!--             <div class="form-check mt-3 text-center">-->
<!--               <input class="form-check-input" type="radio" name="gender" value="1" checked v-model="form.gender" >-->
<!--               <label class="form-check-label"> მამრობითი</label>-->

<!--               <input class="form-check-input ml-4" type="radio" name="gender" value="0" v-model="form.gender">-->
<!--               <label class="form-check-label ml-5 "> მდედრობითი</label>-->

<!--             </div>-->
<!--               </div>-->


<!--             <div class="col-sm-12 col-md-8 ">-->
<!--             <div class="form-group">-->
<!--               <select class="form-control" v-model="form.userlebelID" id="userlebelID">-->
<!--                 <option value="0" selected>აირჩიეთ როლი</option>-->
<!--                 <option v-for="(userlevel,i) in userlevels" :key="i" :value="userlevel.userlevelID">{{userlevel.userlevel}}</option>-->

<!--               </select>-->


<!--             </div>-->

<!--                </div>-->

<!--             <div class=" col-sm12  col-md-3">-->
<!--               <div class="form-group">-->
<!--                 <input type="file"  @change="getimg">-->

<!--               </div>-->

<!--             </div>-->
<!--             <div class="col-sm12 col-md-9">-->
<!--               <select class="form-control" v-model="form.profesiaID" id="profesiaID">-->
<!--                 <option value="0" selected>აირჩიეთ პროფესია</option>-->
<!--                 <option v-for="(proff,i) in profesia" :key="i" :value="proff.profesiaID">{{proff.profesia}}</option>-->

<!--               </select>-->


<!--             </div>-->

<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label >ხელშეკრულების ნომერი</label>-->
<!--                 <input type="text" class="form-control" placeholder="" v-model="form.contract_number" id="contract_number">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label >ხელშეკრულების თარიღი</label>-->
<!--                 <input type="date" class="form-control"  v-model="form.contract_date" id="contract_date">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label >ხელშეკრულების დად. თარიღი</label>-->
<!--                 <input type="date" class="form-control"  v-model="form.contract_start_date" id="contract_start_date">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label >ხელშეკრულების დას. თარიღი</label>-->
<!--                 <input type="date" class="form-control"  v-model="form.contract_end_date" id="contract_end_date">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label >შეწყვეტის თარიღი</label>-->
<!--                 <input type="date" class="form-control" v-model="form.shewyvetis_tar" id="shewyvetis_tar">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label >თანხა</label>-->
<!--                 <input type="text" class="form-control" v-model="form.salary" id="salary">-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label>ხელშეკრულების ტიპი</label>-->
<!--                 <Multiselect id="contract_typeID"-->
<!--                     v-model="form.contract_typeID"-->
<!--                     :options="contract_type"-->
<!--                     :searchable="true"-->
<!--                 />-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-3">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label>სტატუსი</label>-->
<!--                 <Multiselect id="statusID"-->
<!--                     v-model="form.statusID"-->
<!--                     :options="status"-->
<!--                     :searchable="true"-->
<!--                 />-->
<!--               </div>-->
<!--             </div>-->
<!--             <div class="col-sm-12  col-md-6 col-lg-6">-->
<!--               <div class="form-group mt-2">-->
<!--                 <label>დადების თარიღი</label>-->
<!--                 <input type="date" class="form-control"  v-model="form.putdate" id="putdate">-->
<!--               </div>-->
<!--             </div>-->


<!--           </div>-->
<!--           <button type="button" class="btn btn-secondary btn-block" @click="saveinfo">შენახვა</button>-->

<!--         </form>-->







  </div>



</template>
<script>
import Multiselect from "@vueform/multiselect";

export default {
  components: {Multiselect},
  data(){
    return{
      formData: new FormData(),
      form:{
        fname: null,
        lname:null,
        phone:null,
        adress:null,
        email:null,
        piradoba:null,
        piradobisS:null,
        dabadebistarigi:null,
        userlebelID:0,
        gender:1,
        profesiaID:0,
        contract_typeID:0,
        statusID:0,
        contract_number:null,
        contract_date:null,
        shewyvetis_tar:null,


        userlevels:null,
        profesia:null,



        contract_start_date:null,
        contract_end_date:null,



      },

      status:null,
      contract_type:null,

    }


  },mounted() {
    this.$store.dispatch('getusers').then(response=>{

      this.userlevels=response.data
    })
    this.$store.dispatch('getcontract_type').then(response=>{

      this.contract_type=response.data
    })
    this.$store.dispatch('getstatus').then(response=>{

      this.status=response.data
    })
    this.$store.dispatch('getprof').then(response=>{
      this.profesia=response.data

    })
  },methods:{
    getimg(event) {
      this.formData.append('img',event.target.files[0])
    },
    getfile(event){
      this.formData.append('file',event.target.files[0])

      console.log(event.target.files[0])
    },

    saveinfo(){

      this.formData.append('gender', this.form.gender)
      this.formData.append('lname', this.form.lname)
      this.formData.append('fname', this.form.fname)
      this.formData.append('phone', this.form.phone)
      this.formData.append('adress', this.form.adress)
      this.formData.append('email', this.form.email)
      this.formData.append('piradoba', this.form.piradoba)
      this.formData.append('contract_start_date', this.form.contract_start_date)
      this.formData.append('contract_end_date', this.form.contract_end_date)
      this.formData.append('shewyvetis_tar', this.form.shewyvetis_tar)

      this.formData.append('contract_date', this.form.contract_date)
      this.formData.append('contract_number', this.form.contract_number)
      this.formData.append('statusID', this.form.statusID)
      this.formData.append('contract_typeID', this.form.contract_typeID)
      this.formData.append('salary', this.form.salary)
      this.formData.append('piradobisS', this.form.piradobisS)
      this.formData.append('profesiaID', this.form.profesiaID)
      this.formData.append('userlebelID', this.form.userlebelID)


      this.formData.append('branchID',this.$store.state.user.branchID)


        this.$store.dispatch('saveinfo', this.formData).then(response => {


          if(response.data.status=='OK'){
            this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით დაემატა')
          }else if(response.data.status=='photo.ext.error'){

            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, სურათი უნდა იყოს .jpg ფორმატის')
          }else if(response.data.status=='photo.empty.error'){

            this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა, გთხოვთ აირჩიოთ სურათი')

          }


        })






    }




  }


}
</script>