<template>

  <div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-labelledby="edit" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form  v-for="(pers,i) in personinfo" :key="i">
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="name">სახელი</label>
                <input type="text"  class="form-control" id="name" v-model="form.name">
              </div>

              <div class="form-group col-md-6">
                <label for="gvari">გვარი</label>
                <input type="text"  class="form-control" id="gvari" v-model="form.gvari">
              </div>
              <div class="form-group col-md-12">
                <label for="email">ელფოსტა</label>
                <input type="email" class="form-control" id="email" v-model="form.email" >
              </div>
            </div>
            <div class="form-group">
              <label for="addres">მისამართი</label>
              <input type="text" class="form-control" id="addres" v-model="form.addres">
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="strar_contract">კონტრაქტის დაწყება</label>
                <input type="date" class="form-control" id="strar_contract" v-model="form.start_contract">
              </div>
              <div class="form-group col-md-6">
                <label for="end_contract">კონტრაქტის დასრულება</label>
                <input type="date" class="form-control" id="end_contract" v-model="form.end_contract">
              </div>
              <div class="form-group col-md-6">
                <label for="end_contract_end">კონტრაქტის შეწყვეტა</label>
                <input type="date"  class="form-control" id="end_contract_end" v-model="form.end_contract_end">
              </div>
              <div class="form-group col-md-6">
                <label for="contract_number">კონტრაქტის ნომერი</label>
                <input type="text" class="form-control" id="contract_number" v-model="form.contract_number">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="salary">ხელფასი</label>
                <input type="number" class="form-control" id="salary" v-model="form.salary">
              </div>
              <div class="form-group col-md-6">
                <label >კონტრაქტის ტიპი</label>
                 <Multiselect
                  v-model="form.value"
                  :options="getcontactType"
                  :searchable="true"
              />
              </div>

            </div>
            <div class="form-group">
              <label for="number">მობილური</label>
              <input type="text" class="form-control" id="number" v-model="form.number">
            </div>
            <div class="form-group">
              <label for="number">პირადობის ნომერი</label>
              <input type="text" class="form-control" id="number" v-model="form.piradoba">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light w-100" @click="contracts()">რედაქტირება</button>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-12" v-show="hidden">
      <div style="display: flex" >
        <div style="margin-left: 10px;color:red">{{saxeli}} {{gvari}}</div>
        <div style="margin-left: 10px;color:red">დარჩენილია:{{count}}დღე</div>

      </div>
    </div>
  </div>
  <table class="table table-striped table-responsive" style="font-size: 13px;">
    <thead>
    <tr>
      <th scope="col">პერსონალი</th>
      <th scope="col">მისამართი</th>
      <th scope="col">მობილური</th>
      <th scope="col">ელფოსტა</th>
      <th scope="col">კონტრაქტის დაწყება</th>
      <th scope="col">კონტაქტის დასრულება</th>
      <th scope="col">შეწყვეტის თარიღი</th>
      <th scope="col">პირადი ნომერი</th>
      <th scope="col">კონტაქტის ნომერი</th>
      <th scope="col">ხელფასი</th>
      <th scope="col">კონტაქტის ტიპი</th>
      <th>
        <input type="text" v-model="searchWord" class="form-control">
      </th>



    </tr>
    </thead>
    <tbody>
    <tr v-for="(currentpersoninfo,i) in filteredPersons" :key="i" style="text-align: center;">
      <th scope="row">{{currentpersoninfo.saxeli}} {{currentpersoninfo.gvari}}</th>
      <td>{{currentpersoninfo.misamarTi}}</td>
      <td>{{currentpersoninfo.mobiluri}}</td>
      <td>{{ currentpersoninfo.elfosta }}</td>
      <td>{{currentpersoninfo.contract_start_date}}</td>
      <td>{{ currentpersoninfo.contract_end_date }}</td>
      <td>{{currentpersoninfo.shewyvetis_tar}}</td>
      <td>{{currentpersoninfo.piradoba}}</td>
      <td>{{currentpersoninfo.contract_number}}</td>
      <td>{{currentpersoninfo.salary}}</td>
      <td>{{currentpersoninfo.contract_type}}</td>


      <td><button @click="editPerson(currentpersoninfo.contractID)" type="button" class="btn btn-light" data-toggle="modal" data-target="#edit">რედაქტირება</button></td>
    </tr>
    </tbody>
  </table>

</template>

<script>
import Multiselect from "@vueform/multiselect";

export default {
  components: {Multiselect},
  data(){

    return{
      formData: new FormData(),
      form: {
        name:null,
        email:null,
        addres:null,
        start_contract:null,
        end_contract:null,
        salary:null,
        value:0,
        number:null,
        piradoba:null,
        gvari:null,
        end_contract_end:null,
        contract_number:null


      },
      getcurrent:null,
      update:false,
      contractype:null,

      personinfo:null,
      getcontactType:null,
      insertcontracts:null,
      count:null,
      x:null,
      y:null,
      hidden:false,
      saxeli:null,
      gvari:null,
      searchWord:'',
      filteredPersons:null


    }
  },mounted() {
    this.$store.dispatch('getcurrentperson',{branchID:this.$store.state.user.branchID}).then(response=>{

      this.x= this.getcurrent=response.data[0].contract_start_date
      this.y=this.getcurrent=response.data[0].contract_end_date
      this.saxeli=this.getcurrent=response.data[0].saxeli
      this.gvari=this.getcurrent=response.data[0].gvari
      let date1 = new Date(this.y);
      let date2 = new Date();
      let diffTime = Math.abs(date2 - date1);
      this.count = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

      if (this.count<20){
        this.hidden=true

      }else{

      }


      this.getcurrent=response.data
      this.filteredPersons=response.data

    })
    this.coundays()

  },
  watch:{
    searchWord(val){
        if(val!=''){

          this.filteredPersons=this.getcurrent.filter(o=>o.saxeli.includes(val) || o.gvari.includes(val))
        }else {
          this.filteredPersons=this.getcurrent
        }


    }


  },

  methods:{
    editPerson(contractID){

      this.$store.dispatch('editPerson',{contractID:contractID}).then(response=>{
        this.form.end_contract_end= response.data[0].shewyvetis_tar
        this.form.contract_number= response.data[0].contract_number
        this.form.gvari= response.data[0].gvari
        this.form.name= response.data[0].saxeli
        this.form.contractID= response.data[0].contractID
        this.form.personalID= response.data[0].personalID
        this.form.email= response.data[0].elfosta
        this.form.addres= response.data[0].misamarTi
        this.form.start_contract= response.data[0].contract_start_date
        this.form.end_contract= response.data[0].contract_end_date
        this.form.salary= response.data[0].salary
        this.form.piradoba= response.data[0].piradoba
        this.form.number= response.data[0].mobiluri
        this.personinfo=response.data
        this.form.value=response.data[0].contract_typeID






      })



      this.getcontactTypee()
    },
    getcontactTypee(){
      this.$store.dispatch('getcontactType').then(response => {
        this.getcontactType = response.data

      })
    },

    contracts(){
      this.$store.dispatch('insertcontracts',this.form).then(response => {



        if(response.data.status=='OK'){
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით განახლდა')
        }else if(response.data.status=='ERROR'){

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }


      })
    },
    coundays(){
      this.$store.dispatch('getcontactType').then(response => {

        this.getcontactType = response.data

      })

    },
    async loadUsers() {
      const response = await this.$store.dispatch('searchcontractpers')
      console.log(response.data)
      // this.getcurrent = await response.data
      //
      // return this.getcurrent
    },
  }

}

</script>