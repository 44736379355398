<template>
  <div class="modal fade" id="edit" tabindex="-1" role="dialog" aria-labelledby="edit" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">

          <button type="button" class="close" data-dismiss="modal" aria-label="Close">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <form>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="name">სახელი</label>
                <input type="text"  class="form-control" v-model="form.saxeli">
              </div>

              <div class="form-group col-md-6">
                <label for="gvari">გვარი</label>
                <input type="text"  class="form-control"  v-model="form.gvari">
              </div>
              <div class="form-group col-md-12">
                <label for="email">ელფოსტა</label>
                <input type="email" class="form-control" v-model="form.elfosta">
              </div>
            </div>
            <div class="form-group">
              <label for="addres">მისამართი</label>
              <input type="text" class="form-control" v-model="form.misamarTi" >
            </div>
            <div class="form-row">
              <div class="form-group col-md-6">
                <label for="mamisaxeli">მამისახელი</label>
                <input type="text" class="form-control" v-model="form.mamisaxeli">
              </div>
              <div class="form-group col-md-6">
                <label for="dabadebistarigi">დაბადების თარიღი</label>
                <input type="date" class="form-control" id="dabadebistarigi" v-model="form.dabadebistarigi">
              </div>
            </div>
            <div class="form-row">
              <div class="form-group col-md-12">
                <label for="piradiN">პირადი N</label>
                <input type="text" class="form-control" id="piradiN" v-model="form.piradi_num">
              </div>


            </div>
            <div class="form-group">
              <label for="number">მობილური</label>
              <input type="text" class="form-control" id="number" v-model="form.mobiluri">
            </div>
          </form>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-light w-100" @click="updatemoswinfoOo()">რედაქტირება</button>
        </div>
      </div>
    </div>
  </div>
  <table class="table table-striped">
    <thead>
    <tr>
      <th scope="col">მოსწავლე</th>
      <th scope="col">პირადი:N</th>
      <th scope="col">თანხა</th>
      <th scope="col">კოდი</th>
      <th scope="col">კლასი</th>
      <th scope="col">კონტრაქტის დაწყება</th>
      <th></th>
    </tr>
    </thead>
    <tbody>
    <tr v-for="(accruals,i) in Studentaccruals" :key="i">
      <td>{{accruals.mosw}}</td>
      <td>{{accruals.piradi_num}}</td>
      <td>{{accruals.fee}}</td>
      <td>{{accruals.kodi}}</td>
      <td>{{accruals.klasi}}</td>
      <td>{{accruals.contr_start}}</td>
      <td><button @click="editmoswinfoo(accruals.moswavleID)" type="button" class="btn btn-info"  data-toggle="modal" data-target="#edit">რედაქტირება</button></td>
    </tr>

    </tbody>
  </table>

</template>
<script>
export default {
  data(){
    return{
      formData: new FormData(),
      form: {
        saxeli: null,
        gvari:null,
        misamarTi:null,
        mamisaxeli:null,
        piradi_num:null,
        elfosta:null,
        dabadebistarigi:null,
        mobiluri:null,
        moswavleID:null,


      },
      Studentaccruals:null,
      editmoswinfo:null,
      update:null,
    }

  },mounted() {
    this.$store.dispatch('studentaccruals').then(response=>{

      this.Studentaccruals=response.data
    })
  },methods:{
    editmoswinfoo(moswavleID){
      this.$store.dispatch('editmoswinfo',{moswavleID:moswavleID}).then(response=>{

        this.form.saxeli= response.data[0].saxeli
        this.form.gvari= response.data[0].gvari
        this.form.misamarTi= response.data[0].misamarTi
        this.form.mamisaxeli= response.data[0].mamisaxeli
        this.form.piradi_num= response.data[0].piradi_num
        this.form.elfosta= response.data[0].elfosta
        this.form.dabadebistarigi= response.data[0].dabadebistarigi
        this.form.mobiluri= response.data[0].mobiluri
        this.form.moswavleID= response.data[0].moswavleID

        this.editmoswinfo=response.data
        console.log(response.data)
      })
    },
    updatemoswinfoOo(){
      this.$store.dispatch('updatemoswinfoO',this.formData).then(response=>{
        this.formData.append('moswavleID', this.form.moswavleID)
        this.formData.append('saxeli', this.form.saxeli)
        this.formData.append('gvari', this.form.gvari)
        this.formData.append('misamarTi', this.form.misamarTi)
        this.formData.append('mamisaxeli', this.form.mamisaxeli)
        this.formData.append('piradi_num', this.form.piradi_num)
        this.formData.append('dabadebistarigi', this.form.dabadebistarigi)
        this.formData.append('mobiluri', this.form.mobiluri)


        if (response.data.status == 'OK') {
          this.$helper.notifySuccessMessage('ჩანაწერი წარმატებით ჩაიწერა')
        } else if (response.data.status == 'ERROR') {

          this.$helper.notifyErrorMessage('დაფიქსირდა შეცდომა')
        }
        this.update=response.data

      })




    }


  }

}
</script>